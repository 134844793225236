import axios from "@/plugins/axios.js"

const actions = {
    setChatSearchQuery({ commit }, query){
        commit('SET_CHAT_SEARCH_QUERY', query)
    },
    // app CALLS
    sendChatMessage({ getters, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${rootState.domain}/api/app/chats/create`, payload)
          .then((response) => {
            if(response.data.success){
              commit('SEND_CHAT_MESSAGE', response.data.data.chat)
              payload.chatData = getters.chatDataOfUser(payload.to)
              if(!payload.chatData) { dispatch("fetchContacts") }
            }
            resolve(response)
          }).catch((error) => { reject(error) })
      })
    },
    sendChatAttachments({ getters, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${rootState.domain}/api/app/chats/save-files`, payload)
        .then((response) => {
            if(response.data.success){
              commit('SEND_CHAT_MESSAGE', response.data.data.chat)
              payload.chatData = getters.chatDataOfUser(payload.to)
              if(!payload.chatData) { dispatch("fetchContacts") }

            }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    // Get contacts from server. Also change in store
    fetchContacts({ commit, rootState, }) {
      return new Promise((resolve, reject) => {
        axios.get(`${rootState.domain}/api/app/chats/contacts`)
          .then((response) => {
            if(response.data.success){
              commit('SET_CONTACTS', response.data.data.contacts)
            }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    // Get chats from server. Also change in store
    fetchChats({ commit, rootState, }) {
      return new Promise((resolve, reject) => {
        axios.get(`${rootState.domain}/api/app/chats`)
          .then((response) => {
            if(response.data.success){
              commit('SET_USER_CHATS', response.data.data.chats)
            }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    markSeenAllMessages({ getters, rootState, commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${rootState.domain}/api/app/chats/${id}/mark-all-seen`)
          .then((response) => {
            if(response.data.success){
              commit('MARK_SEEN_ALL_MESSAGES', {
                id: id,
                chatData: getters.chatDataOfUser(id)
              })
            }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

}

const getters = {

    chatDataOfUser: (state, getters, rootState) => id => {
        return state.chats.filter((chat) => (chat.from == rootState.AppActiveUser.id && chat.to == id)  || (chat.from  == id && chat.to == rootState.AppActiveUser.id))
    },

    contacts: (state, getters) => {
        let contacts = state.contacts.filter((contact) => contact.first_name.toLowerCase().includes(state.chatSearchQuery.toLowerCase()) || contact.last_name.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
        let contWithLastM =  contacts.filter((contact) => getters.chatLastMessaged(contact.id)? true : false);
        let contWithoutLastM =  contacts.filter((contact) => getters.chatLastMessaged(contact.id)? false : true);
        contWithLastM.sort((x,y) => {
            let timeX = getters.chatLastMessaged(x.id).created_at
            let timeY = getters.chatLastMessaged(y.id).created_at
            return (new Date(timeY) - new Date(timeX))
        })
      return [...contWithLastM, ...contWithoutLastM];
    },

    contact: (state) => contactId => state.contacts.find((contact) => contact.id == contactId),

    chats: (state) => state.chats,

    chatUser: (state, getters, rootState) => id => state.contacts.find((contact) => contact.id == id) || rootState.AppActiveUser,

    chatLastMessaged: (state, getters) => id => {
        if(id && getters.chatDataOfUser(id).length) return JSON.parse(JSON.stringify(getters.chatDataOfUser(id))).slice(-1)[0];
        else
        return false
    },
    chatUnseenMessages: (state, getters, rootState) => id => {
        let unseenMsg = 0;
        const chatData = getters.chatDataOfUser(id);
        if(chatData) {
            chatData.map((msg) => {
              if((msg.from != rootState.AppActiveUser.id) && !msg.is_read) unseenMsg++;
            })
        }
        return unseenMsg;
    },
}

const mutations  = {
    UPDATE_ABOUT_CHAT(state, obj) {
      obj.rootState.AppActiveUser.about = obj.value
    },
    UPDATE_STATUS_CHAT(state, obj) {
      obj.rootState.AppActiveUser.status = obj.value
    },
    // API AFTER
    SEND_CHAT_MESSAGE(state, chat) {
        state.chats.push(chat)
    },
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts
    },
  
    SET_USER_CHATS(state, chats) {
      state.chats = chats
    },
    
    SET_CHAT_SEARCH_QUERY(state, query) {
      state.chatSearchQuery = query
    },
    MARK_SEEN_ALL_MESSAGES(state, payload) {
      payload.chatData.forEach((msg) => {
        msg.is_read = 1
      })
    },
  
}

const state  = {
    // Chat Search Query
    chatSearchQuery: "",

    // Stores All Contacts
    contacts: [],
  
    // Stores Chat data(log)
    chats: [],
  }


export default {
	isRegistered: true,
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
