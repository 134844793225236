import Vue from 'vue'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  let arr = value.split(" ")
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(" ");
})

Vue.filter('title', function (value, replacer="_") {
  if (!value) return ''
  value = value.toString()

  let arr = value.split(replacer)
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(" ");
})

Vue.filter('truncate', function(value, limit) {
  if(!value){return};
  if(value.length > limit){
    return value.substring(0, limit)+'...'
  }else{
    return value
  }
})

Vue.filter('delivery_time', function(time) {
  if(!time){return}
  return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
})

Vue.filter('truncate_start', function(value, limit) {
  if(value.length > limit){
    return '...'+value.substring(value.length, value.length - limit)
  }else{
    return value
  }
})

Vue.filter('tailing', function(value, tail) {
  return value + tail;
})

Vue.filter('time', function(value, is24HrFormat = false) {
  if(value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if(!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM';
      hours = hours % 12 || 12;
      return hours + ':' + min + ' ' + time
    }
    return hours + ':' + min
  }
})

Vue.filter('date_parse', function(value, fullDate = false) {
  var options1 = { month: 'numeric', day: 'numeric' };
  var options2 = {year: 'numeric', month: 'numeric', day: 'numeric' };
  var givenDate  = new Date(value);
  if(!value || givenDate == 'Invalid Date'){return;}
  if(!fullDate) return givenDate.toLocaleDateString("en-US", options1);
  else return givenDate.toLocaleString("en-US",  options2);

})

Vue.filter('date', function(value, fullDate = false) {
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var givenDate  = new Date(value);
  if(!value || givenDate == 'Invalid Date'){return;}
  if(!fullDate) return givenDate.toLocaleDateString("en-US", options);
  else return givenDate.toLocaleString("en-US");
})

Vue.filter('date_time', function(value, index = 0) {
  var options = [
    { weekday:'long', year:'numeric', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
    { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' }
  ];
  var givenDate  = new Date(value);
  return new Intl.DateTimeFormat('en-US', options[index]).format(givenDate)
})

Vue.filter('month', function(val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if(!showYear) {
    return regx.exec(val)[1];
  }else {
    return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
  }

})

Vue.filter('csv', function(value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "")
})

Vue.filter('k_formatter', function(num) {
  return num > 999 ? (num/1000).toFixed(1) + 'k' : num
})

Vue.filter('money_format', function(number, decimal=true) {
  let i = parseFloat(number); if(isNaN(i)) {return;}
  return i.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
})

Vue.filter('date_ago', function(utctime) {
  let x        = new Date(utctime)
 if(!utctime || x == 'Invalid Date'){return;}

  let now      = new Date()
  var timeDiff = now - x
  timeDiff    /= 1000

  var seconds = Math.round(timeDiff)
  timeDiff    = Math.floor(timeDiff / 60)

  var minutes = Math.round(timeDiff % 60)
  timeDiff    = Math.floor(timeDiff / 60)

  var hours   = Math.round(timeDiff % 24)
  timeDiff    = Math.floor(timeDiff / 24)

  var days    = Math.round(timeDiff % 365)
  timeDiff    = Math.floor(timeDiff / 365)

  var years   = timeDiff

  if (years > 0) {
    return years + (years > 1 ? ' years ' : ' year ') + 'ago'
  } else if (days > 0) {
    return days + (days > 1 ? ' days ' : ' day ') + 'ago'
  } else if (hours > 0) {
    return hours + (hours > 1 ? ' hrs ' : ' hour ') + 'ago'
  } else if (minutes > 0) {
    return minutes + (minutes > 1 ? ' mins ' : ' min ') + 'ago'
  } else if (seconds > 0) {
    return seconds + (seconds > 1 ? ' sec ago' : 'just now')
  }
  return 'just Now'

})

