export default [
  {
  // =============================================================================
  // MAIN LAYOUT ROUTES - Main Routes
  // =============================================================================
      path: '',
      redirect: '/',
      component: () => import('@layouts/Main.vue'),
      children: [
          {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                pageTitle: 'Dashboard',
                authRequired: true,
              }
          },
          {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                  breadcrumb: [
                      { title: 'Home', url: '/' },
                      { title: 'Account', active: true },
                  ],
                  pageTitle: 'Profile',
                  authRequired: true,
              },
          },
          {
            path: '/users',
            name: 'users',
            component: () => import('@views/users/UserList.vue'),
            meta: {
                breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Users', active: true },
                ],
                pageTitle: 'User List',
                authRequired: true,
            },
        },
        {
              path: '/users/:userId(\\d+)',
              name: 'user-show',
              component: () => import('@views/users/UserView.vue'),
              meta: {
                  breadcrumb: [
                      { title: 'Home', url: '/' },
                      { title: 'Users' , url: '/users' },
                      { title: 'User View', active: true },
                  ],
                  pageTitle: 'User',
                  authRequired: true,
              },
          },
          {
            path: '/users/:userId(\\d+)/edit',
            name: 'user-edit',
            component: () => import('@views/users/edit/UserEdit.vue'),
            meta: {
                breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Users', url: '/users' },
                    { title: 'Edit', active: true },
                ],
                pageTitle: 'User Edit',
                parent: 'users',
                authRequired: true,

            },
          },
          {
              path: '/orders',
              name: 'orders',
              component: () => import('@views/orders/OrderList.vue'),
              meta: {
                  breadcrumb: [
                      { title: 'Home', url: '/' },
                      { title: 'Orders', active: true },
                  ],
                  pageTitle: 'Orders',
                  authRequired: true,
              },
          },
          {
              path: '/orders/:orderId(\\d+)',
              name: 'order-view',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Pending', url: '/orders?status=pending' },
                    { title: 'Delivered', url: '/orders?status=delivered' },
                    { title: 'Revision', url: '/orders?status=revision' },
                    { title: 'Completed', url: '/orders?status=completed' },
                    { title: 'Order View', active: true },
                ],
                pageTitle: 'Orders',
                authRequired: true,
              },
          },
          {
            path: '/reviews',
            name: 'reviews',
            component: () => import('@views/reviews/Reviews.vue'),
            meta: {
              breadcrumb: [
                  { title: 'Home', url: '/' },
                  { title: 'Reviews', active: true },
              ],
              pageTitle: "Reviews",
              authRequired: true,
            }
          },
          {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notification/Notifications.vue'),
              meta: {
                  breadcrumb: [
                      { title: 'Home', url: '/' },
                      { title: 'Notification List', active: true },
                  ],
                  pageTitle: 'Notifications',
                  authRequired: true,
              }
          },
          {
              path: '/chat',
              name: 'chat',
              component: () => import('@views/chat/Chat.vue'),
              meta: {
                pageTitle: 'Chats',
                authRequired: true,
              }
          },
          {
            path: '/payments',
            name: 'payments',
            component: () => import('@views/payments/Payments.vue'),
            meta: {
                breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Payment List', active: true },
                ],
                pageTitle: 'Payments',
                authRequired: true,
          }
        },
      ]
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
      path: '',
      component: () => import('@layouts/FullPage.vue'),
      children: [
          {
              path: '/login',
              name: 'login',
              component: () => import('@views/pages/Login.vue'),
              meta: {
                pageTitle: 'Login',
                guestRequired: true
              }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@views/pages/Error404.vue'),
              meta: {
                pageTitle: '404 Error',
                guestRequired: true
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@views/pages/Error500.vue'),
              meta: {
                pageTitle: '500 Error',
                guestRequired: true
              }
          },
      ]
  },
  // Redirect to 404 page, if no match found
  {
      path: '*',
      redirect: '/error-404'
  },

]